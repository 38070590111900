<template>
    <base-page>
        <titlu-pagina Titlu="Avizari formulare" @on_add_clicked="show_dialog()" :AdaugaVisible='false' />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        <el-col :span='4'>
                            <el-form-item label='Numar inregistrare' >
                                <el-input v-model='Filters.NumarInregistrare' />
                            </el-form-item>
                        </el-col>  
                        <el-col :span='4'>
                            <el-form-item label='Stare' prop="Stare">
                                <el-select class='full-width' v-model="Filters.Stare" placeholder="Select">
                                    <el-option label="Creat"  value="creat"></el-option>
                                    <el-option label="In lucru"  value="in_lucru"></el-option>
                                    <el-option label="Solutionat" value="solutionat"></el-option>
                                    <el-option label="Respins"  value="respins"></el-option>
                                    <el-option label="Draft"  value="draft"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>        
                        <!-- <el-col :span='4'>
                            <el-form-item label='Status' prop="Status">
                                <el-select class='full-width' v-model="Filters.Status" placeholder="Select">
                                    <el-option label="Creat"  value="creat"></el-option>
                                    <el-option label="Trimis" value="trimis"></el-option>
                                    <el-option label="Primit"  value="primit"></el-option>
                                    <el-option label="Validat"  value="validat"></el-option>
                                    <el-option label="Invalidat"  value="invalidat"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>                -->
                        <el-col :md='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" :row-class-name="tableRowClassName">
            <el-table-column prop='IdIntrare' label='Numar inregistrare'></el-table-column>
            <el-table-column prop='NumeSolicitant' label='Solicitant'></el-table-column>
            <el-table-column prop='DataExpirare' label='Data expirare'></el-table-column>
            <el-table-column prop='DataExpirare' label='Data expirare'></el-table-column>
            <el-table-column prop='Stare' label='Stare'></el-table-column>
            <!-- <el-table-column prop='Status' label='Status'></el-table-column>             -->
            <el-table-column fixed="right" label="Actiuni" width="250px" >
                <template slot-scope="scope" >
                    <div v-if="scope.row.Status == 'trimis'">
                        <el-tooltip content="Primire document">
                            <el-button type="primary" icon="el-icon-finished" circle @click="confirmaPrimireDocument(scope.row.IdIntrare)" />
                        </el-tooltip>  
                    </div>
                    <div v-else>
                        <el-tooltip content="Vizualizare">
                            <el-button type="primary" icon="el-icon-view" circle @click="show_dialog(scope.row.IdFormular)" />
                        </el-tooltip>
                        <el-tooltip content="Respingere">
                            <el-button type="danger" icon="el-icon-error" circle @click="openDialogRespingere(scope.row.IdFormular)" />
                        </el-tooltip>   
                        <el-tooltip content="Documente" >
                            <el-button icon="el-icon-folder-opened" circle @click="uploadDocs(scope.row.IdFormular)" />
                        </el-tooltip>
                     </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
            title="Respingere Aviz"
            :visible.sync="respingereDialogVisible"
            width="40%">
            <div class="sub-title">Motiv respingere</div><br>
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="Introdu motivul respingerii"
                v-model="motivRespingere">
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="respingereDialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="respingeAviz(IdFormularRespingere)">Confirm</el-button>
            </span>
        </el-dialog>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <UploadDocumente ref="uploadDoc"/>	
        <SemnareDocument ref="semnareDoc" @parolaValida="avizareDocument()"/>	
        <Formular-dialog ref='dlg' @save="refresh_info()" :validationMode='true' @formularValidat="semnareFormular" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import UploadDocumente from '@/components/UploadDocumente'
import SemnareDocument from '@/components/SemnareDocument'
import Formular_dialog from '@/pages/formular/Formular_dialog.vue';

export default {
    name: "avizari_formulare",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
        UploadDocumente,
        SemnareDocument,
        'Formular-dialog': Formular_dialog,
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { functii_angajati: [], departamente: []},
            Filters: { NumarInregistrare: '', Status: '', Stare: '' },                
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            OptiuniTipAngajat: [{ value: 'Propriu', label: 'Propriu'}, { value: 'Cooptat',label: 'Cooptat' }],
            idFormular: -1,
            respingereDialogVisible: false,
            motivRespingere: '',
            IdFormularRespingere: -1
        }
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
            if (row.Status == 'trimis') {
            return 'danger-row';
            } else if (row.Status == 'primit') {
            return 'warning-row';
            }
            return 'success-row';
        },
        semnareFormular(id){
            this.refresh_info();
        },
        async get_info(){
            var response                = await this.post("angajati/get_info" );
            this.Info.functii_angajati  = response.functii_angajati;
            this.Info.departamente      = response.departamente;
            this.refresh_info();
        },
        async refresh_info(){  
            var response        = await this.post("formulare_avizari/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('avizari_formulare_list');
        },
        reset(){
            this.Filters = { NumarInregistrare: '', Status: '',  Stare: '' }
            this.refresh_info();
        },
        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("angajati/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },
        async openDialogRespingere(idFormular){
            this.respingereDialogVisible = true;
            this.IdFormularRespingere = idFormular;
        },

        async respingeAviz(idFormular){
            this.IdFormularRespingere = -1;
            this.respingereDialogVisible = false;

            let res = await this.post('Formulare/respingeAvizare', {
                idFormular: idFormular,
                motivRespingere: this.motivRespingere
            });
            this.refresh_info();
        },

        verificaSemnatura(idFormular){            
            this.idFormular = idFormular
            this.$refs.semnareDoc.verificaSemnatura()                        
        },
        async avizareDocument(){           
            var next_step   = await this.getNextStepRezolvare(this.idFormular)
            const h         = this.$createElement;
            var message     = h('p', null, [ h('span', null, 'Doriti validarea?') ])

            this.$confirm(message, 'Atentie!',{
                confirmButtonText: 'Valideaza',
                cancelButtonText: 'Anuleaza'
                }).then(() => {
                    // this.trimiteSpreRezolvare(id, next_step);
                    this.$message({type: 'success', message: 'Validare efectuata cu succes'});
                }).catch(() => {
                    this.$message({type: 'info',message: 'Validare anulata' });                                                                                      
            });
        },
        async trimiteSpreRezolvare(id, next_step){
            await this.post('formulare/trimitere_spre_rezolvare', {idFormular: id, idFunctie: next_step.id});
        },
        async getNextStepRezolvare(id_formular){
            var result = await this.post('formulare/get_next_step_rezolvare', {idFormular: id_formular});
            return {id:result.next_function_id, name: result.next_function_name}
        },
        show_dialog(id){
            console.log(id);
            this.$refs.dlg.show_me(id);
        },
        uploadDocs(idFormular){
            this.$refs.uploadDoc.showMe(idFormular)
        },
        confirmaPrimireDocument(id){           
            const h     = this.$createElement;
            var message = h('p', null, [ h('span', null, 'Confirmati primirea documentului?') ])

            this.$confirm(message, 'Atentie!',{
                confirmButtonText: 'Confirmare',
                cancelButtonText: 'Anulare'
                }).then(() => {
                    this.valideazaPrimireDocument(id)
                }).catch(() => {
                    this.$message({type: 'info',message: 'Primire document anulata' });                                                                                      
            });
        },
        async valideazaPrimireDocument(id){
            var result = await this.post('formulare_avizari/validare_primire_document', {id: id})
            if(!result.Error){
                this.$message({type: 'success', message: 'Primire document efectuata cu succes'});
                this.refresh_info()
            }
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" >

    .top50{
        margin-top: 20px;
    }
    .proper{
        margin-top: 20px;
        text-transform: 'capitalize';
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

    .el-table .warning-row {
        background: oldlace;
    }
    .el-table .success-row {
        background: #f0f9eb;
    }
    .el-table .danger-row {
        background: #f05b6c;
        color: white;
    }

</style>
