<template>
    <el-dialog title="Login" :visible.sync="showPopupSemnatura" width="30%" :append-to-body="true">			
        <div v-if="eroare_semnatura">
            <span class="eroare">Nu exista semnatura incarcata</span>
        </div>
        <div v-else>
            <img width="70%" :src="imagineSemnatura" />			

            <el-form :inline="true" :model='selectedObject' ref='verificareParola' @submit.prevent="verificaParola" v-loading="loader" >					
                <el-form-item label="Parola">
                    <el-input type="password" v-model="selectedObject.password" placeholder='Parola dvs' class='full-width'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="verificaParola"> Semneaza documentul </el-button>
                </el-form-item>					
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import VueSignaturePad from "vue-signature-pad";

    export default {
        name: "UploadSemnatura",
        extends: BasePage,
        components:{
            VueSignaturePad
        },
        data () {
            return {
                showPopup: false,
                selectedObject: { Nume: '', Semnatura: null, password:'' },
                loadingVisible: true,
                loader: false,
                imagineSemnatura: '',
                idUser: '',
                showPopupSemnatura: false,
                eroare_semnatura: false,
                idFormular:-1
            }
        },
        methods: {     
            post: async function(url, args={}){
                this.loadingVisible      = true;
                var             response = await this.$http.post(url, args );
                this.loadingVisible      = false;
                if( settings.verify_response(response) )
                {
                    return response.body;
                }
                else
                {
                    this.$router.push("/");
                }
            },       
            async get_info(id){
                this.showPopup      = true;
                this.loadingVisible = true
                var response        = await this.post("utilizatori/get_semnatura", { id: id } );
                this.selectedObject = response.Utilizator;                    
                this.$refs.signaturePad.fromDataURL(this.selectedObject.Semnatura)
                this.loadingVisible = false
            },
            save: async function() {
                const { isEmpty, data }         = this.$refs.signaturePad.saveSignature()                
                this.selectedObject.Semnatura   = data
                
                await this.post("utilizatori/save_semnatura", { object: this.selectedObject } );
                this.showPopup = false;
                this.$refs.signaturePad.undoSignature();
            },
            undo() {
                this.$refs.signaturePad.undoSignature()
            },
            async verificaParola(){
                var result = await this.post('utilizatori/verifica_parola', {password: this.selectedObject.password})
                if(!result.Error){
                    this.$emit('parolaValida')
                }
                else
                    this.$message.error('Parola gresita');
            },
            async semneaza(){
                this.showPopupSemnatura	= true
                this.loader 			= true
                var response 			= await this.verificaSemnatura()
                this.imagineSemnatura	= response.Semnatura
                this.idUser				= response.UserId

                if(response.Error)
                    this.eroare_semnatura = true

                this.loader = false
            },
            async verificaSemnatura(){
                this.showPopupSemnatura	= true
                this.loader 			= true
                var result 		        = await this.post('utilizatori/verifica_semnatura');
                this.imagineSemnatura	= result.Semnatura
                this.idUser				= result.UserId

                if(result.Error)
                    this.eroare_semnatura = true

                this.loader = false              
            },
            setezaIdSemnatura(){			
                this.selectedObject.Variabile.forEach(v=>{
                    if(v.Nume == 'semnatura')
                        v.Value = this.idUser
                })
            }
        },
        mounted: function() {
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .border-signature {
      border:2px solid #CECECE;
    }

</style>